import React, { Fragment } from "react"

/* Import Global Component(s) */
import SEO from "~components/seo"

/* Import Local Component(s) */
import Header from "./temporary-dwellings/components/header/header"
import LiveSymposiumIcon from "./temporary-dwellings/components/liveSymposiumIcon/liveSymposiumIcon"
import OralHistoryRadioChannelIcon from "./temporary-dwellings/components/oralHistoryRadioChannelIcon/oralHistoryRadioChannelIcon"
import PerformanceLibraryIcon from "./temporary-dwellings/components/performanceLibraryIcon/performanceLibraryIcon"
import PortalPressIcon from "./temporary-dwellings/components/portalPressIcon/portalPressIcon"
import ResearchAndThemesIcon from "./temporary-dwellings/components/researchAndThemesIcon/researchAndThemesIcon"
import ResourcesIcon from "./temporary-dwellings/components/resourcesIcon/resourcesIcon"

/* Import Local Style(s) */
import "./temporary-dwellings/temporary-dwellings.css"

const TemporaryDwellings = () => (
  <Fragment>
    <SEO title="Temporary Dwellings" />
    <div className="page temporary-dwellings">
      <Header />
      <div className="container">
        <div className="content">
          <p>Temporary Dwellings is coming soon...</p>
          <div className="icons">
            <LiveSymposiumIcon />
            <OralHistoryRadioChannelIcon />
            <PerformanceLibraryIcon />
            <PortalPressIcon />
            <ResearchAndThemesIcon />
            <ResourcesIcon />
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default TemporaryDwellings
